import React from "react";
import cx from "classnames";

import LoadingSection from "../LoadingSection";
import { useLocalization } from "../../../context/LocalizeContextProvider";
import { Pagination } from "react-bootstrap";
import SelectField from "../SelectField";

export default function Table({
  columns,
  children,
  loading,
  activePage,
  totalPage = 0,
  totalShow = 0,
  totalEntries = 0,
  handlePagination,
  noPagination = false,
  isHovered = false,
  handleSizePerPage = () => undefined,
  sizePerPage = 50,
  sortState = null,
  setSortState = () => undefined,
}) {
  const localize = useLocalization();

  const showingStart =
    totalShow !== 0 ? (activePage === 0 ? 1 : activePage * 10 + 1) : 0;
  const showingOfEntries = totalShow !== 0 ? showingStart + totalShow - 1 : 0;

  let items = [];
  for (let number = 1; number <= totalPage; number++) {
    if (
      number !== totalPage &&
      number <= activePage + 4 &&
      number >= activePage - 2
    ) {
      items.push(
        <Pagination.Item
          key={number}
          active={number - 1 === activePage}
          onClick={() => handlePagePagination(number - 1)}
        >
          {number}
        </Pagination.Item>
      );
    }
  }

  items.push(
    <>
      {activePage + 4 <= totalPage && <Pagination.Ellipsis />}
      <Pagination.Item
        key={totalPage}
        active={totalPage - 1 === activePage}
        onClick={() => handlePagePagination(totalPage - 1)}
      >
        {totalPage}
      </Pagination.Item>
    </>
  );

  const handlePagePagination = (number) => {
    handlePagination(number);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="table-responsive">
      <div className="h-100 pb-3">
        <table
          className={cx(
            isHovered && "table table-hover",
            "display w-100 dataTable"
          )}
        >
          <thead>
            <tr role="row">
              {columns.map((item, index) => (
                <th className={item?.className} style={item.style} key={index}>
                  <div className="d-flex align-items-center gap-2">
                    {item.label}
                    {item?.isSort && (
                      <>
                        {!sortState?.value ? (
                          <i
                            className="fas fa-sort c-pointer"
                            onClick={() =>
                              setSortState({ key: item?.key, value: "asc" })
                            }
                          ></i>
                        ) : sortState?.value === "asc" &&
                          sortState.key === item.key ? (
                          <i
                            className="fas fa-sort-up c-pointer"
                            onClick={() =>
                              setSortState({ key: item?.key, value: "desc" })
                            }
                          ></i>
                        ) : sortState.key === item.key ? (
                          <i
                            className="fas fa-sort-down c-pointer"
                            onClick={() => setSortState({ key: "", value: "" })}
                          ></i>
                        ) : null}
                      </>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <td colSpan={100} className="text-center">
                <LoadingSection />
              </td>
            ) : totalEntries <= 0 ? (
              <td colSpan={100} className="text-center">
                {localize.getText("noData")}
              </td>
            ) : (
              children
            )}
          </tbody>
        </table>

        {!noPagination && totalEntries !== 0 && (
          <div className="d-flex flex-column text-center justify-content-between align-items-center mt-3 gap-3">
            <p className="mb-0" style={{ fontSize: 14 }}>
              {localize.getText("ShowingOfEntries", {
                totalShow: showingStart,
                totalShowOfEntries: showingOfEntries,
                totalEntries: totalEntries,
              })}
            </p>
            <div className="d-flex align-items-center justify-content-center gap-4 flex-wrap">
              <Pagination>
                {totalPage >= 20 && (
                  <Pagination.First onClick={() => handlePagePagination(0)} />
                )}
                {activePage > 0 && (
                  <Pagination.Prev
                    onClick={() =>
                      activePage >= 0 && handlePagePagination(activePage - 1)
                    }
                  />
                )}
                {items}

                {activePage + 1 < totalPage && (
                  <>
                    <Pagination.Next
                      onClick={() =>
                        activePage >= 0 && handlePagePagination(activePage + 1)
                      }
                    />
                    {totalPage >= 20 && (
                      <Pagination.Last
                        onClick={() => handlePagePagination(totalPage - 1)}
                      />
                    )}
                  </>
                )}
              </Pagination>
              <SelectField
                placeholder={localize.getText("entriesPerPage")}
                data={[
                  { value: 5, label: 5 },
                  { value: 10, label: 10 },
                  { value: 50, label: 50 },
                  { value: 100, label: 100 },
                ]}
                onChange={(value) => handleSizePerPage(value)}
                value={sizePerPage}
                style={{ width: 70 }}
                isBorder
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
