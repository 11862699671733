import React from "react";
import { Form, Field } from "react-final-form";

import { useHome } from "../../../context/HomeProvider";
import useLocation from "../../../hooks/SatuSehat/useLocation";
import { useLocalization } from "../../../context/LocalizeContextProvider";

import Modal from "../Modal";
import Spinner from "../Spinner";
import Button from "../Button";
import AsyncSelect from "../AsyncSelect";
import InputScheduleField from "../InputScheduleField";

export default function ModalTransferPatient({
  show,
  isLoadingTransfer,
  handleModal,
  dataTransferPatient,
  handleChangeTransferPatient,
  handleSubmitTransfer,
}) {
  const localize = useLocalization();
  const { dataDoctors, isLoadingDoctor } = useHome();
  const { dataLocations, isLoading: isLoadingLocation } = useLocation();

  return (
    <Modal visible={show} centered handleHideModal={() => handleModal(null)}>
      <Spinner loading={isLoadingTransfer}>
        <Form
          initialValues={dataTransferPatient}
          validate={(values) => {
            const errors = {};
            if (!values.doctorId) {
              errors.doctorId = localize.getText("notValidField", {
                fieldName: localize.getText("doctorOrTerapisTarget"),
              });
            }
            if (!values.timeVisit || values?.timeVisit.length === 0) {
              errors.timeVisit = localize.getText("notValidField", {
                fieldName: localize.getText("selectTime"),
              });
            }
            return errors;
          }}
          onSubmit={handleSubmitTransfer}
        >
          {({ handleSubmit }) => (
            <div className="d-flex gap-5 flex-column">
              <h3 className="m-0">{localize.getText("movePatient")}</h3>
              <div className="d-flex flex-column gap-4">
                <Field name="doctorId">
                  {({ input, meta }) => (
                    <AsyncSelect
                      label={localize.getText("doctorOrTerapisTarget")}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("doctorOrTerapisTarget"),
                      })}
                      isLoading={isLoadingDoctor}
                      options={dataDoctors}
                      value={dataTransferPatient.doctorId}
                      error={meta.touched && meta.error}
                      onBlur={input.onBlur}
                      onChange={(value) => {
                        handleChangeTransferPatient("doctorId", value);
                      }}
                      isClearable
                      labelKey="name"
                      valueKey="id"
                    />
                  )}
                </Field>
                <Field name="timeVisit">
                  {({ meta }) => (
                    <InputScheduleField
                      noHeader
                      label={localize.getText("selectTime")}
                      error={meta.touched && meta.error}
                      doctorAvailability={dataTransferPatient.availableSlot}
                      doctorId={dataTransferPatient?.doctorId?.value}
                      durationVisit={dataTransferPatient?.durationVisit}
                      bookingDate={dataTransferPatient?.reservationDate}
                      value={dataTransferPatient.timeVisit || []}
                      onClick={(value) => {
                        handleChangeTransferPatient("timeVisit", value);
                      }}
                    />
                  )}
                </Field>
                <Field name="locationId">
                  {({ input, meta }) => (
                    <AsyncSelect
                      label={localize.getText("location")}
                      value={dataTransferPatient.locationId}
                      options={dataLocations}
                      placeholder={localize.getText("placeholderField", {
                        fieldName: localize.getText("location"),
                      })}
                      error={meta.touched && meta.error}
                      onBlur={input.onBlur}
                      isLoading={isLoadingLocation}
                      labelKey="name"
                      valueKey="id"
                      onChange={(value) => {
                        handleChangeTransferPatient("locationId", value);
                      }}
                    />
                  )}
                </Field>
              </div>
              <div className="d-flex items-center justify-content-end gap-2">
                <Button
                  label="Tutup"
                  variant="outline-primary"
                  onClick={() => handleModal(null)}
                />
                <Button
                  label="Simpan"
                  onClick={handleSubmit}
                  loading={isLoadingTransfer}
                />
              </div>
            </div>
          )}
        </Form>
      </Spinner>
    </Modal>
  );
}
