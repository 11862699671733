import axios from "axios";
import moment from "moment";
import { postPatient } from "../PatientService";
import { postStartVisit, postEndVisit } from "../AssesmentService";
import { getItemStorage } from "../../utils/utils";

export const postEndToStartVisitReservation = async (data) => {
  try {
    const responseEndVisit = await endVisitReservation({
      ...data.previousReservation,
      status: "DONE",
      note: null,
      fileUrl: null,
    });
    if (responseEndVisit.status === 200) {
      const responseStartVisit = await postStartVisitReservation(
        data.newReservation
      );

      return responseStartVisit;
    }
    return responseEndVisit;
  } catch (error) {
    return error;
  }
};

export const postEndVisitReservation = async (data) => {
  try {
    const responsePost = await postEndVisit(data);

    if (responsePost.status === 200) {
      const responseUpdate = await updateReservation({
        booking_id: data.bookingId,
        patient_id: data.patientId,
        status: "DONE",
      });

      return responseUpdate;
    }

    return responsePost;
  } catch (error) {
    return error;
  }
};

export const postStartVisitReservation = async (data) => {
  try {
    const responsePost = await postStartVisit({ ...data, bookingId: null });

    if (responsePost.status === 200) {
      if (!data.bookingId) {
        const responseReservation = await postBookingReservation({
          ...data.reservation,
          patientId: responsePost?.data || data.patientId,
          locationId: data.locationId,
        });
        return responseReservation;
      } else {
        const responseUpdate = await updateReservation({
          booking_id: data.bookingId,
          patient_id: responsePost?.data || data.patientId,
          location_id: data.locationId,
          status: "ACTIVE",
        });

        return responseUpdate;
      }
    }

    return responsePost;
  } catch (error) {
    return error;
  }
};

export const postBookingReservation = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_LAMBDA_URL + `/save-reservation`,
      data
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const postNewPatientReservation = async (data) => {
  try {
    const alergiesDTOs = data.allergies.map((item) => ({
      ...item,
      ...(item.__isNew__ ? {} : { __isNew__: false }),
    }));
    const dataSavePatient = {
      name: data.name,
      phoneNumber: data.phoneNumber,
      gender: data.gender,
      address: data.address,
      remark: data.remark,
      identificationType: data.identificationType,
      identificationNumber: data.identificationNumber,
      medicalRecordNumber: data.medicalRecordNumber,
      dob: moment(data.dateOfBirth, "YYYY-MM-DD").format("DD-MM-YYYY"),
      allergy: alergiesDTOs,
    };

    const response = await postPatient(dataSavePatient);

    if (response.status === 200) {
      if (!!data?.isUpdate) {
        const dataUpdateReservation = {
          booking_id: data?.booking_id || null,
          patient_id: response.data.id,
          location_id: data?.locationId || "",
        };

        const responseUpdate = await updateReservation(dataUpdateReservation);
        return responseUpdate;
      } else {
        const dataSaveReservation = {
          ...data,
          patientId: response.data.id,
        };
        const responseReservation = await postBookingReservation(
          dataSaveReservation
        );

        return responseReservation;
      }
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};

export const updateReservation = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_LAMBDA_URL + `/update-reservation`,
      data
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const endVisitReservation = async (data) => {
  const tokenDetails = getItemStorage("userDetails") || null;

  try {
    const response = await axios.post(
      process.env.REACT_APP_LAMBDA_URL + `/v1/end-visit-reservation`,
      data,
      {
        headers: {
          authorization: "Bearer " + tokenDetails.id_token,
        },
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getDetailReservation = async (id) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_LAMBDA_URL + `/v1/get-reservation-detail/${id}`
    );

    return response;
  } catch (error) {
    return error;
  }
};

export const getReservations = async (
  busines_id,
  reservation_date,
  doctor_id
) => {
  const tokenDetails = getItemStorage("userDetails") || null;

  try {
    let link =
      process.env.REACT_APP_LAMBDA_URL +
      `/v1/get-reservation-data?business_id=${busines_id}`;

    if (reservation_date)
      link = link.concat(`&reservation_date=${reservation_date}`);

    if (doctor_id) link = link.concat(`&doctor_id=${doctor_id}`);

    const response = await axios.get(link, {
      headers: {
        authorization: "Bearer " + tokenDetails.id_token,
      },
    });

    return response;
  } catch (error) {
    return error;
  }
};

export const postTransferPatient = async (data) => {
  try {
    const tokenDetails = getItemStorage("userDetails") || null;

    const response = await axios.post(
      process.env.REACT_APP_LAMBDA_URL + `/v1/post-transfer-patient`,
      data,
      {
        headers: {
          authorization: "Bearer " + tokenDetails.id_token,
        },
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};
