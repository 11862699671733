import React from "react";
import Table from "../../../components/Table";
import Button from "../../../components/Button";
import { useLocalization } from "../../../../context/LocalizeContextProvider";
import { Link } from "react-router-dom";
// import { useModal } from "../../../../context/ModalProvider";
import { formatNumberWithCurrency } from "../../../../utils/utils";

export default function ItemComponent({
  dataItem,
  isLoading,
  mutateDelete,
  totalPage = 0,
  totalEntries = 0,
  sizePerPage = 0,
  handleSetSizePerPage,
  handlePagination,
  activePage,
  sortState,
  setSortState,
}) {
  const localize = useLocalization();
  // const modal = useModal();

  const columns = [
    { label: localize.getText("id"), key: "id" },
    { label: localize.getText("name"), key: "name" },
    { label: localize.getText("price"), isSort: true, key: "price" },
    { label: localize.getText("category-item"), key: "category" },
    {
      label: localize.getText("action"),
      className: "text-center",
      style: { width: "200px" },
    },
  ];

  return (
    <div className="card">
      <div className="card-header d-flex justify-content-end">
        <Link to="/item/add-item">
          <Button label={localize.getText("addItem")} size="btn-sm" />
        </Link>
      </div>
      <div className="card-body">
        <Table
          columns={columns}
          loading={isLoading}
          activePage={activePage}
          totalPage={totalPage}
          totalShow={dataItem.length}
          totalEntries={totalEntries}
          handlePagination={handlePagination}
          sizePerPage={sizePerPage}
          handleSizePerPage={handleSetSizePerPage}
          sortState={sortState}
          setSortState={setSortState}
        >
          {dataItem.map((item, index) => (
            <tr key={index}>
              <td className="sorting_1">{item.id}</td>
              <td>{item.name}</td>
              <td>{formatNumberWithCurrency(item.price)}</td>
              <td>{item.category_name}</td>
              <td>
                <div className="d-flex justify-content-center gap-3">
                  {/* <Button
                    variant="danger"
                    onClick={() =>
                      modal.showConfirmation({
                        title: `${localize.getText("areYouSure")}?`,
                        message: localize.getText("messageDeleteData"),
                        onConfirm: () => mutateDelete(item.id),
                      })
                    }
                  >
                    <i class="fas fa-trash"></i>
                  </Button> */}
                  <Link
                    to={{
                      pathname: "/item/detail-item",
                      search: `?id=${item.id}`,
                    }}
                  >
                    <Button variant="warning">
                      <i class="fas fa-eye"></i>
                    </Button>
                  </Link>
                  <Link
                    to={{
                      pathname: "/item/edit-item",
                      search: `?id=${item.id}`,
                    }}
                  >
                    <Button variant="info">
                      <i class="fas fa-edit"></i>
                    </Button>
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
}
